import { put, takeLatest } from "redux-saga/effects";
import { PaymentTypes, PaymentActions } from "store/ducks/payment";
import api from "services/api";
import {
  GET,
  PATCH,
  POST,
  PAYMENT_BY_TOKEN,
  UPDATE_PAYMENT,
  PAYMENT_METHODS,
  PAYMENT_GATEWAYS,
  PAYMENT_BY_BILLET,
  DOWNLOAD_GATEWAY_INVOICE,
  PREPARE_DOWNLOAD_GATEWAY_INVOICE,
  baseURL,
} from "utils/constants";

function* fetchEnterpriseByToken({ token, origin }) {
  try {
    const { data } = yield api({
      method: GET,
      url: PAYMENT_BY_TOKEN(token),
    });
    yield put(PaymentActions.fetchEnterpriseByTokenSuccess(data, origin));
  } catch (error) {
    yield put(PaymentActions.fetchEnterpriseByTokenFailure(error));
  }
}

function* updatePaymentToken({ subscriptionToken, tokenPagarMe }) {
  try {
    const { data } = yield api({
      method: PATCH,
      url: UPDATE_PAYMENT(subscriptionToken),
      data: { tokenPagarMe },
    });
    yield put(PaymentActions.updatePaymentTokenSuccess(data));
  } catch (error) {
    yield put(PaymentActions.updatePaymentTokenFailure(error));
  }
}

export function* fetchPaymentMethods() {
  try {
    const { data } = yield api({
      method: GET,
      url: PAYMENT_METHODS,
    });
    yield put(PaymentActions.fetchPaymentMethodsSuccess(data));
  } catch (error) {
    yield put(PaymentActions.requestFailure(error, "fetchPaymentMethods"));
  }
}

export function* fetchPaymentGateways() {
  try {
    const { data } = yield api({
      method: GET,
      url: PAYMENT_GATEWAYS,
    });
    yield put(PaymentActions.fetchPaymentGatewaysSuccess(data));
  } catch (error) {
    yield put(PaymentActions.requestFailure(error, "fetchPaymentGateways"));
  }
}

function* paymentByBillet({
  payload: { enterpriseId, invoiceId, paymentGatewayId, paymentToken },
}) {
  try {
    const { data } = yield api({
      method: POST,
      url: PAYMENT_BY_BILLET(enterpriseId),
      data: { invoiceId, paymentToken, paymentGatewayId },
    });
    yield put(PaymentActions.paymentByBilletSuccess(data));
  } catch (error) {
    yield put(PaymentActions.paymentByBilletFailure(error));
  }
}

function* downloadGatewayInvoice({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: PREPARE_DOWNLOAD_GATEWAY_INVOICE,
      data: params,
    });
    yield fetch(`${baseURL}${DOWNLOAD_GATEWAY_INVOICE(data.filename)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Accept: "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    yield put(PaymentActions.downloadGatewayInvoiceSuccess(data));
  } catch (error) {
    yield put(PaymentActions.requestStatusFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(PaymentTypes.FETCH_ENTERPRISE_BY_TOKEN, fetchEnterpriseByToken);
  yield takeLatest(PaymentTypes.UPDATE_PAYMENT_TOKEN, updatePaymentToken);
  yield takeLatest(PaymentTypes.FETCH_PAYMENT_METHODS, fetchPaymentMethods);
  yield takeLatest(PaymentTypes.FETCH_PAYMENT_GATEWAYS, fetchPaymentGateways);
  yield takeLatest(PaymentTypes.PAYMENT_BY_BILLET, paymentByBillet);
  yield takeLatest(PaymentTypes.DOWNLOAD_GATEWAY_INVOICE, downloadGatewayInvoice);
}
