import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { EnterpriseActions } from "store/ducks/enterprise";
import { EmitterActions } from "store/ducks/emitter";
import { PaymentActions } from "store/ducks/payment";
import { TaxesActions } from "store/ducks/taxes";
import WithSpinner from "components/core/WithSpinner";
import { Span, Subtitle } from "components/core/Typography";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { useRequest } from "hooks";
import CardInfo from "components/core/CardInfo";
import { Container, Columns } from "components/core/Grid";
import SubscriptionEdit from "components/presentation/Emitter/Edit/Subscription";
import { subscriptionSchema } from "utils/schemas/subscription";
import { LayoutActions } from "store/ducks/layout";
import { urls } from "utils/constants";
import { parseMoneyToFloat, formatNumberToReal, formatDate } from "helpers";
import { REQUEST_PENDING } from "utils/constants/request";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import { useFinancial } from "hooks";
import { MASK_TYPES } from "utils/masks";

const SubscriptionEditWithSpinner = WithSpinner(SubscriptionEdit);

const SubscriptionContainer = () => {
  const dispatch = useDispatch();
  const submitRef = useRef(null);
  const history = useHistory();
  const { t: translate } = useTranslation();
  const [isMEI, setIsMEI] = useState(true);
  const [planValue, setPlanValue] = useState();
  const [billingRange, setBillingRange] = useState("");
  const [numberProlabore, setNumberProlabore] = useState([]);
  const [payDate, setPayDate] = useState(null);
  const [globalSetting, setGlobalSetting] = useState(null);
  const { globalSettings, getGlobalSettings, requestStatus } = useFinancial();
  const { id } = useParams();
  const { emitterDetails, statusOfRequests } = useSelector(({ emitter }) => emitter);
  const { generalTaxes } = useSelector(({ taxes }) => taxes);

  useEffect(() => {
    if (emitterDetails?.subscription) {
      setNumberProlabore(emitterDetails?.subscription);
    }
  }, [emitterDetails?.subscription]);

  const { types, activities, billingRanges, subscriptionPlans } = useSelector(
    ({ enterprise }) => enterprise,
  );
  const {
    paymentMethods,
    paymentGateways: { items: paymentGateways },
    PaymentRequests: { fetchPaymentGateways: fetchPaymentGatewaysStatus },
  } = useSelector(({ payment }) => payment);

  useEffect(() => {
    getGlobalSettings({
      active: true,
    });
  }, []);

  useEffect(() => {
    if (!types.length) {
      dispatch(EnterpriseActions.getTypes());
    }
  }, [dispatch, types.length]);

  useEffect(() => {
    if (!paymentMethods.length) {
      dispatch(PaymentActions.fetchPaymentMethods());
    }
  }, [dispatch, paymentMethods.length]);

  useEffect(() => {
    if (!paymentGateways.length) {
      dispatch(PaymentActions.fetchPaymentGateways());
    }
  }, [dispatch, paymentGateways.length]);
  useEffect(() => {
    if (emitterDetails?.subscription) {
      setNumberProlabore(emitterDetails?.subscription);
    }
  }, [emitterDetails?.subscription]);
  useEffect(() => {
    getGlobalSettings({
      active: true,
    });
  }, []);
  useEffect(() => {
    if (!generalTaxes.length) {
      dispatch(TaxesActions.fetchTaxes());
    }
  }, [dispatch, generalTaxes.length]);

  useEffect(() => {
    if (emitterDetails?.id !== Number(id)) {
      dispatch(EmitterActions.fetchEmitterById(id));
    }
  }, [dispatch, emitterDetails?.id, id]);

  useEffect(() => {
    const setting = globalSettings?.items?.find(
      (item) => Number(item.enterpriseTypeId) === Number(emitterDetails.idEnterpriseType),
    );
    setGlobalSetting(setting);
  }, [globalSettings, emitterDetails, setGlobalSetting]);

  const handleSubmit = ({ billing, idBillingRange, ...values }) => {
    const newSubscription = { ...values };
    // newSubscription.interestValue = parseMoneyToFloat(newSubscription.interestValue);
    // newSubscription.discountValue = parseMoneyToFloat(newSubscription.discountValue);
    if (isMEI && billing) {
      newSubscription.billing = parseMoneyToFloat(billing);
    } else {
      newSubscription.idBillingRange = Number(idBillingRange);
    }
    dispatch(EmitterActions.editEmitterPlan(id, newSubscription));
  };

  const getEmitterToForm = (emitterToTransform, globalSetting) => {
    if (Object.keys(emitterToTransform).length > 0) {
      const {
        type,
        billing,
        subscription,
        idEnterpriseType,
        paymentPlanType,
        enterpriseInstallmentSetting,
        paymentGatewayDefault
      } = emitterToTransform;
      let nextPaymentDate = null;
      if (subscription[0]?.nextPaymentDate) {
        const dates = subscription[0]?.nextPaymentDate.split("-");
        nextPaymentDate = new Date(dates[0], dates[1] - 1, dates[2]).toLocaleDateString("pt-BR");
      }
      const formValues = {
        idEnterpriseType,
        billing,
        idSubscriptionPlan: subscription[0]?.subscriptionPlan.id,
        idPaymentMethod: subscription[0]?.idPaymentMethod,
        idPaymentGateway: emitterDetails?.idPaymentGateway ?? paymentGatewayDefault.id,
        numberProlabore: subscription[0]?.numberProlabore || 0,
        numberEmployees: subscription[0]?.numberEmployees || 0,
        nextPaymentDate,
        inheritSettings: enterpriseInstallmentSetting
          ? enterpriseInstallmentSetting?.inheritSettings
          : globalSetting?.inheritSettings,
        allowInstallments: enterpriseInstallmentSetting?.allowInstallments || null,
        allowedInstallmentsPlanMonthly:
          enterpriseInstallmentSetting?.allowedInstallmentsPlans.includes(1) || null,
        allowedInstallmentsPlanAnnual:
          enterpriseInstallmentSetting?.allowedInstallmentsPlans.includes(2) || null,
        maxInstallmentsNumber: enterpriseInstallmentSetting?.maxInstallmentsNumber || 1,
        installmentType: enterpriseInstallmentSetting?.installmentType || 1,
        interestValue: enterpriseInstallmentSetting?.interestType
          ? enterpriseInstallmentSetting?.interestType === 1
            ? enterpriseInstallmentSetting?.monthlyInterest
            : enterpriseInstallmentSetting?.annualInterest
          : null,
        discountDescription: subscription[0].discountDescription,
        discountType: subscription[0].discountType,
        discountValue:
          subscription[0].discountType === "V"
            ? formatNumberToReal(subscription[0].discountValue, MASK_TYPES.realWithName)
            : subscription[0].discountType === "P"
            ? subscription[0].discountValue
            : formatNumberToReal("0,00", MASK_TYPES.realWithName),
        paymentPlanType: subscription[0].paymentPlanType ? subscription[0].paymentPlanType : null,
      };
      return formValues;
    }
    return {};
  };

  const getSubscriptionInfo = (emitterToTransform) => {
    if (Object.keys(emitterToTransform).length > 0) {
      const { subscription } = emitterToTransform;
      return {
        idPaymentMethod: subscription[0]?.idPaymentMethod,
        nameSubscriptionPlan: subscription[0]?.subscriptionPlan.name,
      };
    }
    return {};
  };
  const subscriptionInfo = getSubscriptionInfo(emitterDetails);

  const getCouponsInfo = (emitterToTransform) => {
    if (Object.keys(emitterToTransform).length > 0) {
      const { subscription } = emitterToTransform;
      const coupon = subscription[0]?.coupon;
      return {
        id: coupon?.id,
        code: coupon?.code,
        discount: `${coupon?.discount}%`,
      };
    }
    return {
      id: `...${translate("checking")}`,
      code: `...${translate("checking")}`,
      discount: `...${translate("checking")}`,
    };
  };
  const couponsInfo = getCouponsInfo(emitterDetails);

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans i18nKey="planEdited" components={{ bold: <strong /> }} />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(EmitterActions.cleanState());
    dispatch(EmitterActions.fetchEmitterById(id));
    history.push(urls.LINKS.emitterDetails(Number(id)));
  };

  useRequest({
    request: statusOfRequests.editEmitterPlan,
    onResolved,
  });

  useEffect(() => {
    if (emitterDetails?.billing && emitterDetails?.type?.id === 1) {
      setBillingRange(formatNumberToReal(emitterDetails?.billing));
    } else if (
      emitterDetails?.billingRange?.billingMax &&
      emitterDetails?.billingRange?.billingMin
    ) {
      setBillingRange(
        `Entre ${formatNumberToReal(emitterDetails?.billingRange?.billingMin)}
        e ${formatNumberToReal(emitterDetails?.billingRange?.billingMax)}`,
      );
    } else if (
      emitterDetails?.billingRange?.billingMax === null &&
      emitterDetails?.billingRange?.billingMin
    ) {
      setBillingRange(`Acima de ${formatNumberToReal(emitterDetails?.billingRange?.billingMin)}`);
    } else {
      setBillingRange("Não sei");
    }
  }, [emitterDetails]);

  useEffect(() => {
    if (emitterDetails?.subscription?.[0]?.payDate) {
      setPayDate(emitterDetails?.subscription?.[0]?.payDate);
    }
  }, [emitterDetails?.subscription]);
  const handleCancel = () => {
    history.push(urls.LINKS.emitterDetails(Number(id)));
  };

  const requestPending = (status) => {
    return status === REQUEST_PENDING;
  };

  return requestPending(statusOfRequests.editEmitterPlan) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <NavBar title="Alterar Plano do Emissor">
        <Button handleClick={handleCancel} variant="outline">
          {translate("cancel")}
        </Button>
        <Button type="button" handleClick={() => submitRef.current.click()}>
          {translate("save")}
        </Button>
      </NavBar>
      <Container>
        <Subtitle>{translate("currentPlan")}</Subtitle>
        <Columns columns="3">
          <CardInfo label="Tipo de empresa" info={emitterDetails?.type?.name} />
          <CardInfo label="Faturamento Mensal" info={emitterDetails?.billingRange?.billing} />
        </Columns>
        <Columns columns="3">
          <CardInfo label="Quantidade de Funcionários" info={numberProlabore[0]?.numberEmployees} />
          {isMEI === false && (
            <CardInfo
              label="Quantidade de Pró-labores"
              info={numberProlabore[0]?.numberProlabore}
            />
          )}
        </Columns>

        <Columns columns="3">
          <CardInfo
            label="Valor da Parcela"
            info={formatNumberToReal(emitterDetails?.subscription?.[0]?.price)}
          />
          <CardInfo
            label="Valor do Desconto"
            info={formatNumberToReal(emitterDetails?.subscription?.[0]?.discountMonetaryValue)}
          />
        </Columns>

        <Columns columns="3">
          <CardInfo
            label="Valor Liquído da Parcela"
            info={formatNumberToReal(emitterDetails?.subscription?.[0]?.liquidPrice)}
          />
          <CardInfo
            label="Tipo de Plano"
            info={
              emitterDetails?.activePaymentGatewayClientSubscription?.paymentGatewayPlanType?.name
            }
          />
          <CardInfo label="Gateway de Pagamento" info={emitterDetails?.paymentGateway?.name} />
          {/* <CardInfo
            label="Forma de Pagamento"
            info={
              emitterDetails?.activePaymentGatewayClientSubscription?.paymentGatewayMethod?.name
            }
          /> */}
        </Columns>
        {couponsInfo?.id != null && (
          <Columns columns="3">
            <CardInfo label={translate("coupon")} info={couponsInfo?.code} />
            <CardInfo label={translate("discountReceived")} info={couponsInfo?.discount} />
          </Columns>
        )}
        {payDate && (
          <Columns columns="3">
            <CardInfo label={translate("planStartDate")} info={formatDate(payDate)} />
            <CardInfo
              label={translate("planEndDate")}
              info={
                emitterDetails?.subscription?.[0].subscriptionPlan?.id === 1
                  ? formatDate(payDate, 0, true, false, 1)
                  : formatDate(payDate, 0, true, false, 2)
              }
            />
          </Columns>
        )}
      </Container>

      <SubscriptionEditWithSpinner
        requestStatus={statusOfRequests.details}
        isLoading={
          requestStatus.GET_GLOBAL_SETTINGS === REQUEST_PENDING ||
          fetchPaymentGatewaysStatus === REQUEST_PENDING
        }
        onSubmit={handleSubmit}
        defaultValues={getEmitterToForm(emitterDetails, globalSetting)}
        validationSchema={subscriptionSchema}
        submitRef={submitRef}
        types={types}
        activities={activities}
        billingRanges={billingRanges}
        isMEI={isMEI}
        handleSetIsMEI={setIsMEI}
        subscriptionPlans={subscriptionPlans}
        planValue={planValue}
        generalTaxes={generalTaxes}
        paymentMethods={paymentMethods}
        paymentGateways={paymentGateways}
        setPlanValue={setPlanValue}
        oldIdRange={emitterDetails?.billingRange?.idRange}
        numberProlabore={numberProlabore}
        pendingInvoices={emitterDetails?.pendingInvoices}
        oldPlanValue={emitterDetails?.subscription?.[0]?.price}
        globalSetting={globalSetting}
        enterpriseInstallmentSetting={emitterDetails?.enterpriseInstallmentSetting}
        activeSubscriptionId={emitterDetails?.subscription?.[0]?.id}
      />
    </>
  );
};

export default SubscriptionContainer;
